import { Box } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer'
import { NavigationBar } from '../components/ui/NavigationBar';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/actions/auth';
import { HomeScreen } from '../screens/home/HomeScreen';
import { Quicksight } from '../screens/pruebas/QuicksightScreen';
import { SettingsScreen } from '../screens/settings/SettingsScreen';
import { FlowsScreen } from '../screens/flows/FlowsScreen';
import { DocQuery } from '../screens/docQuery/DocQueryScreen';
import { Users } from '../screens/users/UsersScreen';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Main = styled(props => (
  <Box {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.secondary : theme.palette.light.secondary,
}));

export const MainRouter = () => {
  const dispatch = useDispatch();
  const timeout = 3600000;

  const onIdle = () => {
    dispatch(logout());
    // Do some idle action like log out your user
  }

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  }

  const idleTimer = useIdleTimer({ timeout, onIdle, onActive });

  return (
    <Box sx={{ display: 'flex', height: '100%', overflow: 'hidden' }}>
      <NavigationBar />
      <Main
        component="main"
        sx={{
          flexGrow: 1,
          pr: 2,
          pl: 2,
          pb: 7,
          boxShadow: ' 10px 10px 5px -18px rgba(0,0,0,0.75)',
          height: 'calc(100%)',
          overflow: 'auto'
        }}>
        <DrawerHeader />
        <Routes>
          <Route path="/home/*" exact="true" element={<HomeScreen />} />
          <Route path="/Quicksight/*" exact="true" element={<Quicksight />} />
          <Route path="/flows/*" exact="true" element={<FlowsScreen />} />
          <Route path="/settings/*" exact="true" element={<SettingsScreen />} />
          <Route path="/docQuery/:configuration" exact="true" element={<DocQuery />} />
          <Route path="/users/*" exact="true" element={<Users />} />
          <Route path="*" exact="true" element={<Navigate to="/home" />} />
        </Routes>
      </Main>
    </Box>
  )
}
