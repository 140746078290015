import { Edit, MoreVert, Delete } from '@mui/icons-material'
import { Button, Divider, Grid, IconButton, ListItem, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Dialog } from '../../../components/others/Dialog'

export const ItemListComponentFlow = ({
    handleDeleteComponentFlow,
    componentFlow,
    flowSelected
}) => {

    const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
    const params = useParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmDeleteComponentFlow = () => {
        handleDeleteComponentFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            !isNaN(componentFlow.id_flow_component) ? parseInt(componentFlow.id_flow_component) : componentFlow.id_flow_component,
            true
        );
        setOpenDialogConfirmDelete(false);
    }

    return (
        <React.Fragment>
            <ListItem
                secondaryAction={
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip
                            title="Edit"
                        >
                            <IconButton
                                component={Link}
                                to={`/flows/flow-${params.idFlow}/component-flow-${componentFlow.id_flow}`}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title="More"
                        >
                            <IconButton
                                size="small"
                                color="inherit"
                                onClick={handleClick}
                                aria-controls={open ? 'component-flow-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <MoreVert />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            >
                <ListItemText
                    primary={
                        <Typography
                            sx={{
                                display: 'inline-block', maxWidth: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            <Typography component="span" color="red" fontSize={16} fontWeight="bold">{componentFlow.order}</Typography>
                            {' - '}
                            <Typography component="span">{componentFlow.description}</Typography>
                        </Typography>
                    }
                    secondary={
                        <Typography variant="body2" color="textSecondary">
                            {(componentFlow && componentFlow.input !== undefined ? `Input ${componentFlow.input} - ` : '') + componentFlow.type_component + (componentFlow.subtype_component && ` - ${componentFlow.subtype_component}`)}
                        </Typography>
                    }
                />
            </ListItem >
            <Divider />
            <Menu
                anchorEl={anchorEl}
                id="component-flow-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 3,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() => setOpenDialogConfirmDelete(true)}
                >
                    <Delete /> Delete component flow
                </MenuItem>
            </Menu>
            <Dialog
                openDialog={openDialogConfirmDelete}
                widthDialog="xs"
                content={
                    <Typography
                        variant="h7"
                    >
                        Are you sure you want to remove this component?
                    </Typography>
                }
                styleContent={{ px: 3, py: 3 }}
                actions={
                    <Grid
                        item md={12}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            py: 2
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="error"
                            autoFocus
                            size="small"
                            onClick={() => setOpenDialogConfirmDelete(false)}
                            sx={{
                                color: (theme) => theme.palette.text.main,
                                mx: 3
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleConfirmDeleteComponentFlow}
                            size="small"
                        >
                            Delete component flow
                        </Button>
                    </Grid>
                }
            />
        </React.Fragment >
    );
}
