import { Grid } from '@mui/material';
import React from 'react';
import backgroundImage from '../../assets/images/BackgroundRpa.jpg';
import { LayoutLogin } from './layout/LayoutLogin';

export const LoginScreen = () => {

    return (
        <Grid
            container
            sx={{
                background: (theme) => theme.palette.mode === 'dark' ?
                    `linear-gradient( rgba(17, 24, 39, 0.9), rgba(11, 15, 25, 0.8) ), url('${backgroundImage}')`
                    : `linear-gradient( rgba(17, 24, 39, 0.5), rgba(11, 15, 25, 0.6) ), url('${backgroundImage}')`,
                height: '100vh',
                width: '100vw'
            }}
        >
            <LayoutLogin />
        </Grid>
    )
}