import { Grid, List, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ItemListComponentFlow } from '../../screens/flows/detailsFlow/ItemListComponentFlow';

export const ListDragDrop = ({
    numberGridList = 6,
    nameItem,
    keyItem,
    keyItemOrder,
    flowSelected,
    listItems,
    handleDeleteComponentFlow,
    handleChangeOrderItems,
    setItem
}) => {

    const [state, setState] = useState(listItems);

    useEffect(() => {
        // console.log("listItems __", listItems);
        if (listItems && listItems.length > 0)
            setState(listItems);
    }, [listItems]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const newComponentFlows = reorder(
            state,
            result.source.index,
            result.destination.index
        );

        handleChangeOrderItems(newComponentFlows);
        setState(newComponentFlows);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        const newOrder = result.map((item, index) => (
            {
                ...item,
                [keyItemOrder]: index + 1
            }
        ))
        return newOrder;
    };

    const GetStyle = (order) => {
        const cantidadPuntos = order.split('.').length - 1;
        if (cantidadPuntos > 0) {
            return ((cantidadPuntos * 1) + 2);
        }
        return 1;
    }

    return (
        <Grid container>
            <Grid item md={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <List dense={true}>
                                    {
                                        state.map((item, index) => (

                                            <Box sx={{ marginLeft: GetStyle(item.order) }}
                                            // key={item.order}
                                            >
                                                <Draggable
                                                    id={parseFloat(item.order)}
                                                    key={item.order}
                                                    index={parseFloat(item.order)}
                                                    draggableId={`id-${item.order}`}
                                                // style={{ cursor: 'move' }}
                                                >
                                                    {provided => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            onClick={() => setItem(item)}
                                                        >
                                                            <Paper
                                                                variant="outlined"
                                                                square={true}
                                                                sx={{
                                                                    m: 0,
                                                                    borderRadius: 4,
                                                                    bgcolor: (theme) => theme.palette.paper.background
                                                                }}
                                                            >
                                                                <ItemListComponentFlow
                                                                    handleDeleteComponentFlow={handleDeleteComponentFlow}
                                                                    componentFlow={item}
                                                                    flowSelected={flowSelected}
                                                                />
                                                            </Paper>
                                                            {
                                                                listItems?.length === index + 1 || (
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent="flex-end"
                                                                    >
                                                                        <Typography
                                                                            component="span"
                                                                            sx={{
                                                                                display: 'block',
                                                                                borderLeftWidth: '3px',
                                                                                borderLeftStyle: 'solid',
                                                                                marginRight: '50px',
                                                                                borderLeftColor: (theme) => theme.palette.text.main,
                                                                                minHeight: 20
                                                                            }}
                                                                        >
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                            }
                                                        </div>
                                                    )}
                                                </Draggable>
                                            </Box>
                                        ))
                                    }
                                </List>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
        </Grid>
    )
}
