import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import * as Icons from '@mui/icons-material';
import { ModalForms } from '../loadActions/ModalForms';
import { ModalHistory } from '../loadActions/ModalHistory';
import { ShowDocument } from '../loadActions/ShowDocument';
import { useDataAuth } from '../../hooks/useDataAuth';
import { useNotification } from '../../hooks/useNotification';
import { DialogConfirmAction } from '../dialogs/DialogConfirmAction';
import { DownloadAllFiles } from '../others/DownloadAllFiles';

export const LoadButtons = ({ data, handleChange }) => {
    const [{ userSession }] = useDataAuth();
    const [sendNotification] = useNotification();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [tittleDiealog, setTittleDiealog] = useState('');
    const [trigger, setTrigger] = useState(null);
    const [idReception, setIdReception] = useState('');
    const [flowProcess, setFlowProcess] = useState([]);

    useEffect(() => {
        setFlowProcess(data?.configuration ? JSON.parse(data?.configuration)?.flow_process : []);
    }, [data])

    const CallApi = async () => {
        let bodyData = trigger[0]?.bodyData;
        let variables = bodyData.variables;
        const url = trigger[0]?.url;
        const method = trigger[0]?.method;
        const dynamicVars = {
            id_reception: idReception
        };
        for (let key in dynamicVars) {
            const value = dynamicVars[key];
            variables = variables.toString().replace(`{${key}}`, value);
        }

        bodyData.variables = variables;
        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                setOpenConfirmation(false);
                sendNotification({ msg: 'Evento realizado correctamente!', variant: 'success' });
                setTimeout(() => {
                    handleChange();
                }, 500);
            }
            else
                console.error('OK');
        } catch (error) {
            console.error(error);
        }
    }

    const OpenConfirmation = (param, param2, param3) => {
        setOpenConfirmation(true);
        setTrigger(param);
        setIdReception(param2);
        setTittleDiealog(param3);
    }

    return (
        <React.Fragment>
            {
                flowProcess.map((item) => (
                    <React.Fragment>
                        {item.type === 'showDocument' && (
                            <ShowDocument data={item} />
                        )}
                        {item.type === 'trigger' && (
                            <Tooltip title={item.accion}>
                                <IconButton
                                    id={`id_${item.accion}`}
                                    size="small"
                                    sx={{ p: 0 }}
                                    onClick={() => OpenConfirmation(item.trigger, data?.id_reception, item.accion)}
                                >
                                    <span style={{ fontSize: 25, color: item.color }}>
                                        {React.createElement(Icons[item.icon])}
                                    </span>
                                </IconButton>
                            </Tooltip >
                        )}
                        {item.type === 'form' && (
                            <ModalForms data={item} idReception={data?.id_reception} handleChange={handleChange} />
                        )}
                        {item.type === 'HistoricoDocumentos' && (
                            <ModalHistory idReception={data?.id_reception} />
                        )}
                        {item.type === 'DownloadFile' && (
                            <DownloadAllFiles data={item} />
                        )}
                    </React.Fragment>
                ))
            }

            <DialogConfirmAction
                openDialogConfirm={openConfirmation}
                setOpenDialogConfirm={() => setOpenConfirmation(false)}
                titleDialog={tittleDiealog}
                textConfirmAction={"¿Esta seguro que desea continuar?"}
                textButtonConfirm={"Aceptar"}
                textButtonCancel={"Cancelar"}
                handleConfirmAction={CallApi}
            />
        </React.Fragment>
    )
}