import React, { useState } from 'react';
import { Tooltip, Dialog, Typography, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as Icons from '@mui/icons-material';
import axios from 'axios';
import { useDataAuth } from '../../hooks/useDataAuth';
import { Close } from '@mui/icons-material';

export const ShowDocument = ({ data }) => {
    const [{ userSession }] = useDataAuth();
    const [open, setOpen] = useState(false);
    const [pdfBase64, setPdfBase64] = useState("data:application/pdf;base64,");

    const DownloadClick = async (param) => {
        let bodyData = param[0]?.bodyData;
        const url = param[0]?.url;
        const method = param[0]?.method;

        let config = {
            method: method,
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200)
                setPdfBase64(`data:application/pdf;base64,${response.data}`);
            else
                console.error('R_ ', response.status);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <React.Fragment>
            <Tooltip title={data.accion}>
                <IconButton
                    id={'id_' + Math.floor(Math.random() * 615161)}
                    size="small"
                    sx={{ p: 0 }}
                    onClick={() => {
                        setOpen(true);
                        DownloadClick(data.trigger);
                    }}
                >
                    <span style={{ fontSize: 25, color: data.color }}>
                        {React.createElement(Icons[data.icon])}
                    </span>
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                fullWidth
                fullScreen
                PaperProps={{
                    style: { margin: 0, width: 1300, height: 550 },
                }}
            >
                <DialogTitle>
                    <Typography variant="h5" component="h5">
                        Vista pdf
                    </Typography>

                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <iframe
                        title='View PDF'
                        style={{ width: 1250, height: 450 }}
                        src={pdfBase64}
                    />
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}