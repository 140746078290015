import { Button, TextField, Stack, Box, Grid, IconButton, Tooltip, Popover } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import { ValueEditor } from './components/editors/ValueEditor';
import deleteChild from './utils/deleteChild';
import getChild from './utils/getChild';
import setChild from './utils/setChild';
import typeFromValue from './utils/typeFromValue';
import valueFromType from './utils/valueFromType';
import { LevelSelector } from './components/LevelSelector';
import { AddCircle, Delete, Edit } from '@mui/icons-material';

const newArrayItemValue = (subobj) => {
  if (subobj.length > 0) {
    const lastItem = subobj[subobj.length - 1];
    return valueFromType(typeFromValue(lastItem));
  }

  return '';
};

const newObjectItemValue = (subobj) => {
  const objKeys = Object.keys(subobj);

  if (objKeys.length > 0) {
    const lastItem = subobj[objKeys[objKeys.length - 1]];
    return valueFromType(typeFromValue(lastItem));
  }

  return '';
};

const newObjectItemKey = (subobj) => {
  // create a new property name that uses the last property name with new number of the end
  const objKeys = Object.keys(subobj);

  let i = 1;
  let newKey = `item${i}`;
  while (objKeys.includes(newKey)) {
    i += 1;
    newKey = `item${i}`;
  }

  return newKey;
};


export const ModalEditor = ({ 
  arrayKeysPath,
  isEditorAdd,
  isEditorEdit,
  value, 
  onChange 
}) => {

  const [path, setPath] = useState(!arrayKeysPath ? [] : arrayKeysPath);
  const [obj, setObj] = useState(value);
  const [propName, setPropName] = useState(path[path.length - 1]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? `simple-popover-` : undefined;
  
  useEffect(() => {
    setPropName(path[path.length - 1]);
  }, [path]);

  useEffect(() => {
    setObj(value);
  }, [value]);

  const handleClickOpenModal = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClickCloseModal = () => {
      setAnchorEl(null);
  };

  const handleClickSaveModal = () => {
    onChange(obj);
    setAnchorEl(null);
  }

  const setNewPropName = () => {
    const newPath = path.slice(0, path.length - 1);

    if (propName !== undefined) {
      newPath.push(propName);
    }

    const existingItem = getChild(obj, path);
    const newObj = setChild(deleteChild(obj, path), newPath, existingItem);
    setObj(newObj);
    setPath(newPath);
    // onChange(newObj);
  };

  const updateObj = useCallback(
    (subobj) => {
      const newObj = setChild(obj, path, subobj);
      setObj(newObj);
      // onChange(newObj);
    },
    [obj, path, onChange],
  );

  const deleteCurrent = () => {
    const newObj = deleteChild(obj, path);
    setObj(newObj);
    onChange(newObj);
    setAnchorEl(null);
    // setPath(path.slice(0, path.length - 1));
    // onChange(newObj);
  };

  return (
      <Box>
        <Tooltip title={isEditorAdd ? 'Add' : isEditorEdit && 'Edit'}>
            <IconButton
                id={id}
                aria-describedby={id}
                size="small"
                sx={{p:0}}
                onClick={handleClickOpenModal}
            >
                {
                  isEditorAdd ? 
                    <AddCircle sx={{fontSize: 17}}/> 
                  : isEditorEdit && <Edit sx={{fontSize: 17}}/>
                }
            </IconButton>
        </Tooltip>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClickCloseModal}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
          <Box
              sx={{py: 3, px: 2}}
              width={500}
          >
            <Stack
              width="100%"
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <LevelSelector path={path} setPath={setPath} obj={obj} />

              <Stack
                display="flex"
                flex="1"
                width="100%"
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{pt: 1}}
              >
                {/* <Stack
                  display="flex"
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  width="100%"
                  flex="1"
                  spacing={2}
                > */}
                <Grid container>
                  <Grid item md={4} sx={{px:1}}>
                      {typeof propName === 'string' ? (
                        <TextField
                          size="small"
                          fullWidth
                          label="Property Name"
                          value={propName}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => setPropName(e.target.value)}
                          onBlur={setNewPropName}
                        />
                      ) : undefined}
                  </Grid>
                  <ValueEditor
                    arrayKeysPath={arrayKeysPath}
                    newArrayItemValue={newArrayItemValue}
                    newObjectItemValue={newObjectItemValue}
                    newObjectItemKey={newObjectItemKey}
                    value={getChild(obj, path)}
                    onEdit={(index) => setPath([...path, index])}
                    onChange={updateObj}
                  />
                </Grid>

                {path.length > 0 ? (
                  <Box width="100%">
                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={deleteCurrent}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                      <Box sx={{flexGrow: 1}}/>
                      <Button 
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={handleClickSaveModal}
                      >
                          {isEditorAdd ? 'Add' : isEditorEdit && 'Edit'}
                      </Button>
                      <Button 
                          variant="outlined"
                          size="small"
                          color="error"
                          onClick={handleClickCloseModal}
                      >
                          Cancel
                      </Button>
                    </Stack>            
                  </Box>
                ) : undefined}
              </Stack>
            </Stack>
          </Box>
        </Popover>
      </Box>    
  );
}
