import React, { useState, } from 'react';
import { Button, Popover, Typography, TextField, MenuItem, Box, Tooltip, IconButton, FormControlLabel, Switch } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FilterAlt } from '@mui/icons-material';
import moment from 'moment';

export const FilterTable = ({ colum, Search, ResetSearch }) => {
    const [valueFiltStr, setValueFiltStr] = useState('');
    const [filtStr, setFiltStr] = useState('LIKE');
    const [filtSelNum, setFiltSelNum] = useState('=');
    const [filtNum, setFiltNum] = useState(0);
    const [filtBool, setFiltBool] = useState(false);
    const [filtDateInit, setFiltDateInit] = useState(new Date());
    const [filtDateEnd, setFiltDateEnd] = useState(new Date());
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const SetFilter = () => {
        let filt = {};
        if (colum.type === 'string' && valueFiltStr && valueFiltStr !== '') {
            filt = {
                value: valueFiltStr,
                operator: filtStr,
                type: colum.type
            }
        }
        if (colum.type === 'number' && filtNum > 0) {
            filt = {
                value: filtNum,
                operator: filtSelNum,
                type: colum.type
            }
        }
        if (colum.type === 'boolean') {
            filt = {
                value: filtBool,
                operator: '=',
                type: colum.type
            }
        }
        if (colum.type === 'date') {
            filt = {
                initDate: moment(filtDateInit).format('YYYY/MM/DD'),
                endDate: moment(filtDateEnd).format('YYYY/MM/DD'),
                operator: "BETWEEN",
                type: colum.type
            }
        }
        handleClose();
        Search(filt, colum.id);
    }

    const Clear = () => {
        if (colum.type === 'string') {
            setValueFiltStr('');
            setFiltStr('LIKE');
        }
        if (colum.type === 'number') {
            setFiltSelNum('=');
            setFiltNum(0);
        }
        if (colum.type === 'boolean') {
            setFiltBool(false);
        }
        if (colum.type === 'date') {
            setFiltDateInit(new Date());
            setFiltDateEnd(new Date());
        }
        handleClose();
        ResetSearch(colum.id);
    }

    return (
        <React.Fragment>
            <Popover
                id={`popoverId_${colum.id}`}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 150 }}>
                    <Typography align='center' fontSize={13}>
                        Registros con valor que:
                    </Typography>
                    {colum.type === 'string' && (
                        <React.Fragment>
                            <TextField
                                id={`SelectId_${colum.id}`}
                                select
                                label='Operador'
                                variant="outlined"
                                value={filtStr}
                                onChange={(e) => setFiltStr(e.target.value)}
                                size="small"
                                placeholder="select an option"
                            >
                                <MenuItem value='LIKE'>Contiene</MenuItem>
                                <MenuItem value='='>Es igual a</MenuItem>
                                <MenuItem value='<>'>No es igual a</MenuItem>
                            </TextField>

                            <TextField
                                id={`Id_${colum.id}`}
                                label="Valor"
                                variant="outlined"
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                value={valueFiltStr}
                                onChange={(e) => setValueFiltStr(e.currentTarget.value)}
                            />
                        </React.Fragment>
                    )}

                    {colum.type === 'date' && (
                        <React.Fragment>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    id={`inittDateId_${colum.id}`}
                                    label="Init Date"
                                    maxDate={new Date()}
                                    value={filtDateInit}
                                    onChange={(newValue) => setFiltDateInit(newValue)}
                                    renderInput={(params) => (
                                        <TextField size="small" InputLabelProps={{ shrink: true }} {...params} />
                                    )}
                                    inputFormat="dd/MM/yyyy"
                                />
                                <DatePicker
                                    id={`endDateId_${colum.id}`}
                                    label="End Date"
                                    maxDate={new Date()}
                                    minDate={filtDateInit}
                                    value={filtDateEnd}
                                    onChange={(newValue) => setFiltDateEnd(newValue)}
                                    renderInput={(params) => (
                                        <TextField size="small" InputLabelProps={{ shrink: true }} {...params} />
                                    )}
                                    inputFormat="dd/MM/yyyy"
                                />
                            </LocalizationProvider>
                        </React.Fragment>
                    )}

                    {colum.type === 'number' && (
                        <React.Fragment>
                            <TextField
                                id={`SelNumId_${colum.id}`}
                                select
                                label='Operador'
                                variant="outlined"
                                value={filtSelNum}
                                onChange={(e) => setFiltSelNum(e.target.value)}
                                size="small"
                                placeholder="select an option"
                            >
                                <MenuItem value='='>Es igual a</MenuItem>
                                <MenuItem value='>='>Es mayor o igual a</MenuItem>
                                <MenuItem value='<='>Es menor o igual a</MenuItem>
                                <MenuItem value='<>'>No es igual a</MenuItem>
                            </TextField>

                            <TextField
                                id={`numberId_${colum.id}`}
                                label="Valor"
                                variant="outlined"
                                type='number'
                                size="small"
                                InputLabelProps={{ shrink: true }}
                                value={filtNum}
                                onChange={(e) => setFiltNum(e.currentTarget.value)}
                            />
                        </React.Fragment>
                    )}

                    {colum.type === 'boolean' && (
                        <FormControlLabel
                            label='Selecione'
                            control={
                                <Switch
                                    id={`SwitchId_${colum.id}`}
                                    checked={filtBool}
                                    onChange={(e) => setFiltBool(e.target.checked)}
                                />
                            }
                        />
                    )}

                    <Button
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => SetFilter()}
                    >
                        Filtar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        size="small"
                        onClick={() => Clear()}
                    >
                        Limpiar
                    </Button>
                </Box >
            </Popover >

            <Tooltip title='Filtrar'>
                <IconButton
                    id={`id_`}
                    size="small"
                    sx={{ p: 0 }}
                    onClick={handleClick}
                >
                    <FilterAlt fontSize='15px' />
                </IconButton>
            </Tooltip >
        </React.Fragment >
    );
};