import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Dialog } from '../others/Dialog';

export const DialogConfirmAction = ({
    openDialogConfirm,
    setOpenDialogConfirm,
    titleDialog,
    textConfirmAction,
    textButtonConfirm,
    textButtonCancel,
    handleConfirmAction
}) => {

    return (
        <Dialog
            openDialog={openDialogConfirm}
            widthDialog="xs"
            title={titleDialog &&
                <Typography
                    sx={{ fontWeight: 'bold', fontSize: 17 }}
                >
                    {titleDialog}
                </Typography>
            }
            styleTitle={titleDialog && { px: 3, pt: 3 }}
            content={
                <Typography
                    component="div"
                    sx={{ fontSize: 16 }}
                >
                    {textConfirmAction}
                </Typography>
            }
            styleContent={{}}
            actions={
                <Grid
                    item md={12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        py: 1
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        size="small"
                        onClick={() => setOpenDialogConfirm(false)}
                        sx={{
                            mx: 3
                        }}
                    >
                        {textButtonCancel}
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleConfirmAction}
                        size="small"
                    >
                        {textButtonConfirm}
                    </Button>
                </Grid>
            }
        />
    )
}
