import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addNewFlow, deleteFlows, updateFlows, getExecutionsByIdFlow, deleteComponentFlow, changesPendingFlows, changesPendingComponentFlows, changesPendingSubComponentFlows } from "../redux/actions/flows";
import { useDataAuth } from "./useDataAuth";

export const useManageFlows = () => {

    const dispatch = useDispatch();
    const {
        loadingAllFlows,
        updatingFlows,
        deletingFlows,
        deletingComponentFlow,
        addingNewFlow,
        listAllFlows: flows,
        listSchemes,
        loadingExecutions,
        listExecutionsFlow,
        isErrorFetch,
        messageErrorFetch
    } = useSelector(state => state.flows);

    const [listFlows, setListFlows] = useState([]);
    // const [isChangeData, setIsChangeData] = useState(false);
    const [isChangeDataFlows, setIsChangeDataFlows] = useState(false);
    const [isChangeDataComponentFlows, setIsChangeDataComponentFlows] = useState(false);
    const [isChangeDataSubComponentFlows, setIsChangeDataSubComponentFlows] = useState(false);
    const [isNewComponentFlow, setIsNewComponentFlow] = useState(false);
    const [isNewSubComponentFlow, setIsNewSubComponentFlow] = useState(false);
    const [currentBreadCrumb, setCurrentBreadCrumb] = useState(0);
    const [{ userSession, idCompany }] = useDataAuth();

    useEffect(() => {
        setListFlows(flows);
    }, [flows]);

    useEffect(() => {
        dispatch(changesPendingFlows(isChangeDataFlows));
    }, [isChangeDataFlows]);

    useEffect(() => {
        dispatch(changesPendingComponentFlows(isChangeDataComponentFlows));
    }, [isChangeDataComponentFlows]);

    useEffect(() => {
        dispatch(changesPendingSubComponentFlows(isChangeDataSubComponentFlows));
    }, [isChangeDataSubComponentFlows]);

    useEffect(() => {
        setIsChangeDataFlows(updatingFlows);
        setIsChangeDataComponentFlows(updatingFlows);
        setIsChangeDataSubComponentFlows(updatingFlows);
    }, [updatingFlows]);

    const handleDeleteFlows = (arrayByDeleted) => {
        dispatch(deleteFlows(arrayByDeleted, idCompany, userSession.token));
    }

    const handleAddFlow = (newFlow) => {
        const newFlowObject = {
            ...newFlow,
            is_added: true,
            is_updated: false,
            flow_components: !newFlow.flow_components || newFlow.flow_components.length <= 0 ? []
                : newFlow.flow_components?.map(componentFlow => {
                    if (isNaN(componentFlow.id_flow_component)) {
                        return {
                            ...componentFlow,
                            id_flow_component: 0,
                            sub_flow_components: componentFlow.sub_flow_components?.length <= 0 ? []
                                : componentFlow.sub_flow_components?.map(subComponentFlow => {
                                    if (isNaN(subComponentFlow.id_flow_component)) {
                                        return {
                                            ...subComponentFlow,
                                            id_flow_component: 0,
                                        }
                                    } else {
                                        return { ...subComponentFlow };
                                    }
                                })
                        }
                    } else {
                        return {
                            ...componentFlow,
                            sub_flow_components: !componentFlow.sub_flow_components || componentFlow.sub_flow_components?.length <= 0 ? []
                                : componentFlow.sub_flow_components?.map(subComponentFlow => {
                                    if (isNaN(subComponentFlow.id_flow_component)) {
                                        return {
                                            ...subComponentFlow,
                                            id_flow_component: 0,
                                        }
                                    } else {
                                        return { ...subComponentFlow };
                                    }
                                })
                        };
                    }
                })
        }
        dispatch(addNewFlow([newFlowObject], idCompany, userSession.token));
    }

    const handleAddComponentFlow = (idFlow, componentFlowObject) => {
        setIsChangeDataComponentFlows(true);
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {
                    return {
                        ...flow,
                        flow_components: !flow.flow_components || flow.flow_components?.length <= 0 ?
                            [componentFlowObject] :
                            [
                                ...flow.flow_components,
                                componentFlowObject
                            ]
                    }
                } else {
                    return flow;
                }
            })
        ))
    }

    const handleAddSubComponentFlow = (idFlow, idComponentFlow, subComponentFlowObject) => {
        setIsChangeDataSubComponentFlows(true);
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {
                    return {
                        ...flow,
                        flow_components: flow.flow_components?.map(componentFlow => {
                            if (componentFlow.id_flow_component === idComponentFlow) {
                                return {
                                    ...componentFlow,
                                    sub_flow_components: componentFlow.sub_flow_components ? [
                                        ...componentFlow.sub_flow_components,
                                        subComponentFlowObject
                                    ] : [
                                        subComponentFlowObject
                                    ]
                                }
                            } else {
                                return componentFlow;
                            }
                        })
                    }
                } else {
                    return flow;
                }
            })
        ))
    }

    const handleChangeFlow = (idFlow, key, value) => {
        setIsChangeDataFlows(true);
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {
                    return {
                        ...flow,
                        [key]: value
                    };
                } else {
                    return flow
                }
            })
        ));
    }

    const handleChangeComponentFlow = (idFlow, idComponentFlow, key, value) => {
        setIsChangeDataComponentFlows(true);
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {
                    return {
                        ...flow,
                        flow_components: flow.flow_components.map(componentFlow => {
                            if (componentFlow.id_flow_component === idComponentFlow) {
                                return {
                                    ...componentFlow,
                                    is_updated_component: true,
                                    [key]: value
                                };
                            } else {
                                return componentFlow;
                            }
                        })
                    }

                } else {
                    return flow
                }
            })
        ));
    }

    const handleChangeSubComponentFlow = (idFlow, idComponentFlow, idSubComponentFlow, key, value) => {
        setIsChangeDataSubComponentFlows(true);
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {

                    return {
                        ...flow,
                        flow_components: flow.flow_components?.map(componentFlow => {
                            if (componentFlow.id_flow_component === idComponentFlow) {

                                return {
                                    ...componentFlow,
                                    is_updated_component: true,
                                    sub_flow_components: componentFlow.sub_flow_components?.map(subComponentFlow => {
                                        if (subComponentFlow.id_flow_component === idSubComponentFlow) {
                                            return {
                                                ...subComponentFlow,
                                                is_updated_sub_component: true,
                                                [key]: value
                                            };

                                        } else {
                                            return subComponentFlow;
                                        }
                                    })
                                };

                            } else {
                                return componentFlow;
                            }
                        })
                    }

                } else {
                    return flow;
                }
            })
        ));
    }

    const handleReplaceFlow = (flowMain) => {
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === flowMain.id_flow) {
                    return flowMain;
                } else {
                    return flow;
                }
            })
        ));
    }

    const handleDeleteComponentFlow = (idFlow, idComponentFlow, isRequiredSaveChanges) => {
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {

                    return {
                        ...flow,
                        flow_components: flow.flow_components.filter(componentFlow => (
                            componentFlow.id_flow_component !== idComponentFlow
                        ))
                    }

                } else {
                    return flow
                }
            })
        ));
        if (isRequiredSaveChanges) {
            setIsChangeDataFlows(true);
        } else {
            dispatch(deleteComponentFlow([idComponentFlow], userSession.token));
        }
    }

    const handleReplaceComponentFlow = (idFlow, componentFlowOrigin) => {
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {
                    return {
                        ...flow,
                        flow_components: flow.flow_components?.map(componentFlow => {
                            if (componentFlow.id_flow_component === componentFlowOrigin.id_flow_component) {
                                return componentFlowOrigin;
                            } else {
                                return componentFlow;
                            }
                        })
                    }
                } else {
                    return flow;
                }
            })
        ));
    }

    const handleDeleteSubComponentFlow = (idFlow, idComponentFlow, idSubComponentFlow, isRequiredSaveChanges) => {
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {

                    return {
                        ...flow,
                        flow_components: flow.flow_components?.map(componentFlow => {
                            if (componentFlow.id_flow_component === idComponentFlow) {

                                return {
                                    ...componentFlow,
                                    sub_flow_components: componentFlow.sub_flow_components?.filter(subComponentFlow => (
                                        subComponentFlow.id_flow_component !== idSubComponentFlow
                                    ))
                                };

                            } else {
                                return componentFlow;
                            }
                        })
                    }

                } else {
                    return flow;
                }
            })
        ));

        if (isRequiredSaveChanges) {
            setIsChangeDataComponentFlows(true);
        } else {
            dispatch(deleteComponentFlow([idSubComponentFlow], userSession.token));
        }
    }

    const handleReplaceSubComponentFlow = (idFlow, idComponentFlow, subComponentFlowMain) => {
        setListFlows(listFlows => (
            listFlows.map(flow => {
                if (flow.id_flow === idFlow) {
                    return {
                        ...flow,
                        flow_components: flow.flow_components?.map(componentFlow => {
                            if (componentFlow.id_flow_component === idComponentFlow) {

                                return {
                                    ...componentFlow,
                                    sub_flow_components: componentFlow.sub_flow_components?.map(subComponentFlow => {
                                        if (subComponentFlow.id_flow_component === subComponentFlowMain.id_component_flow) {
                                            return subComponentFlowMain;
                                        } else {
                                            return subComponentFlow;
                                        }
                                    })
                                };

                            } else {
                                return componentFlow;
                            }
                        })
                    }
                } else {
                    return flow;
                }
            })
        ));
    }

    const handleUpdatedFlow = (idFlow) => {
        const flow = listFlows.find(fl => fl.id_flow === idFlow);

        const updatedFlow = {
            ...flow,
            is_added: false,
            is_updated: true,
            updated_by: userSession.username,
            updated: moment().toDate(),
            flow_components: !flow.flow_components || flow.flow_components.length <= 0 ? []
                : flow.flow_components?.map(componentFlow => {
                    if (isNaN(componentFlow.id_flow_component)) {
                        return {
                            ...componentFlow,
                            id_flow_component: 0,
                            sub_flow_components: componentFlow.sub_flow_components?.length <= 0 ? []
                                : componentFlow.sub_flow_components?.map(subComponentFlow => {
                                    if (isNaN(subComponentFlow.id_flow_component)) {
                                        return {
                                            ...subComponentFlow,
                                            id_flow_component: 0,
                                        }
                                    } else {
                                        return { ...subComponentFlow };
                                    }
                                })
                        }
                    } else {
                        if (componentFlow.is_updated_component) {
                            return {
                                ...componentFlow,
                                updated_by: userSession.username,
                                updated: moment().toDate(),
                                sub_flow_components: !componentFlow.sub_flow_components || componentFlow.sub_flow_components?.length <= 0 ? []
                                    : componentFlow.sub_flow_components?.map(subComponentFlow => {
                                        if (isNaN(subComponentFlow.id_flow_component)) {
                                            return {
                                                ...subComponentFlow,
                                                id_flow_component: 0,
                                            }
                                        } else {
                                            if (componentFlow.is_updated_sub_component) {
                                                return {
                                                    updated_by: userSession.username,
                                                    updated: moment().toDate(),
                                                    ...subComponentFlow
                                                };
                                            } else {
                                                return { ...subComponentFlow };
                                            }
                                        }
                                    })
                            };
                        } else {
                            return {
                                ...componentFlow,
                                sub_flow_components: !componentFlow.sub_flow_components || componentFlow.sub_flow_components?.length <= 0 ? []
                                    : componentFlow.sub_flow_components?.map(subComponentFlow => {
                                        if (isNaN(subComponentFlow.id_flow_component)) {
                                            return {
                                                ...subComponentFlow,
                                                id_flow_component: 0,
                                            }
                                        } else {
                                            if (componentFlow.is_updated_sub_component) {
                                                return {
                                                    updated_by: userSession.username,
                                                    updated: moment().toDate(),
                                                    ...subComponentFlow
                                                };
                                            } else {
                                                return { ...subComponentFlow };
                                            }
                                        }
                                    })
                            };
                        }

                    }
                })
        }
        dispatch(updateFlows([updatedFlow], idCompany, userSession.token));
    }

    const fetchExecutionsByIdFlow = (idFlow) => {
        dispatch(getExecutionsByIdFlow(idFlow, userSession.token));
    }

    return [{
        loadingAllFlows,
        updatingFlows,
        deletingFlows,
        deletingComponentFlow,
        addingNewFlow,
        listFlows,
        listSchemes,
        isErrorFetch,
        messageErrorFetch,
        isChangeDataFlows,
        isChangeDataComponentFlows,
        isChangeDataSubComponentFlows,
        loadingExecutions,
        listExecutionsFlow,
        isNewComponentFlow,
        isNewSubComponentFlow,
        currentBreadCrumb,
        setIsChangeDataFlows,
        setIsChangeDataComponentFlows,
        setIsChangeDataSubComponentFlows,
        setIsNewComponentFlow,
        setIsNewSubComponentFlow,
        setCurrentBreadCrumb,
        handleDeleteFlows,
        handleAddFlow,
        handleUpdatedFlow,
        handleChangeFlow,
        handleChangeComponentFlow,
        handleChangeSubComponentFlow,
        handleAddComponentFlow,
        handleAddSubComponentFlow,
        handleDeleteComponentFlow,
        handleDeleteSubComponentFlow,
        handleReplaceFlow,
        handleReplaceComponentFlow,
        handleReplaceSubComponentFlow,
        fetchExecutionsByIdFlow
    }];

}
