import styled from '@emotion/styled';
import { AccountCircle, KeyboardArrowDown, Menu as MenuIcon } from '@mui/icons-material';
import { alpha, AppBar as MuiAppBar, Button, Grid, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { saveCompanySelected } from '../../redux/actions/company';
import { useDataAuth } from '../../hooks/useDataAuth';

const drawerWidth = 240;

const StyledMenu = styled((props) => (
  <Menu
    elevation={3}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(0),
    minWidth: 180,
    color: theme.palette.secondary.main,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.main,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: `linear-gradient( to right, ${theme.palette.navigationBar.main}, ${theme.palette.navigationBar.secondary} )`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Navbar = ({ openDrawer, handleDrawerOpen, handleDrawerClose }) => {

  const dispatch = useDispatch();
  const { listCompanies, companySelected } = useSelector(state => state.company)
  const user = JSON.parse(localStorage.getItem('user'));
  const [{ handleLogout }] = useDataAuth();

  const [anchorElMenuCompanies, setAnchorElMenuCompanies] = useState(null);
  const openMenuCompanies = Boolean(anchorElMenuCompanies);
  const handleClickMenuCompanies = (event) => {
    setAnchorElMenuCompanies(event.currentTarget);
  };
  const handleCloseMenuCompanies = () => {
    setAnchorElMenuCompanies(null);
  };

  const [anchorElMenuUser, setAnchorElMenuUser] = useState(null);
  const openMenuUser = Boolean(anchorElMenuUser);
  const handleClickMenuUser = (event) => {
    setAnchorElMenuUser(event.currentTarget);
  };
  const handleCloseMenuUser = () => {
    setAnchorElMenuUser(null);
  };

  const handleClickLogout = () => {
    setAnchorElMenuUser(null);
    handleLogout();
    dispatch(logout());
  }

  const handleClickCompany = (e) => {
    const value = e.target.getAttribute("value");
    const name = e.target.innerText;
    dispatch(saveCompanySelected({
      idCompanySelected: value,
      nameCompanySelected: name
    }));
    setAnchorElMenuCompanies(null);
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      open={openDrawer}
    >
      <Toolbar
        variant="dense"
        sx={{ borderBottom: '1px solid #414141' }}
      >

        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(openDrawer && { display: 'none' }),
          }}
        >
          <MenuIcon color="primary" />
        </IconButton>

        <Grid
          container
        >
          <Grid
            item md={6}
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            {/* <Button
                        id="companies-menu-button"
                        aria-controls={openMenuCompanies ? 'companies-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenuCompanies ? 'true' : undefined}
                        variant="text"
                        disableElevation
                        onClick={handleClickMenuCompanies}
                        endIcon={<KeyboardArrowDown />}
                    >
                        {companySelected.nameCompanySelected}
                    </Button>
                    <StyledMenu
                        id="companies-menu"
                        MenuListProps={{
                        'aria-labelledby': 'companies-menu',
                        }}
                        anchorEl={anchorElMenuCompanies}
                        open={openMenuCompanies}
                        onClose={handleCloseMenuCompanies}
                    >
                        {
                            (listCompanies.length > 0) 
                                ? listCompanies.map((option) => (
                                    <MenuItem 
                                        key={option.id_company} 
                                        value={option.id_company}
                                        onClick={handleClickCompany}
                                        sx={{color: (theme) => theme.palette.text.main}}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))
                                : <div></div>
                        }
                    </StyledMenu> */}
          </Grid>

          <Grid
            item md={6}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Typography>
              {user?.username}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls={openMenuUser ? 'user-menu' : undefined}
              aria-expanded={openMenuUser ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClickMenuUser}
              color="inherit"
            >
              <AccountCircle color="primary" />
            </IconButton>
            <Menu
              keepMounted
              id="user-menu"
              anchorEl={anchorElMenuUser}
              open={openMenuUser}
              onClose={handleCloseMenuUser}
              MenuListProps={{
                'aria-labelledby': 'user-menu',
              }}
            >
              {/* <MenuItem onClick={handleCloseMenuUser}>Profile</MenuItem>
              <MenuItem onClick={handleCloseMenuUser}>My account</MenuItem> */}
              <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
