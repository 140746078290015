import { ArrowBackRounded, LockReset, Mail } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';
import { Box, Divider, FormGroup, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../../hooks/useForm';
import { useNotification } from '../../../hooks/useNotification';

export const FormRecoverPassword = ({ authData }) => {
    const [sendNotification] = useNotification();
    const navigate = useNavigate();
    const {
        creatingNewPassword,
        handleRecoverPassword
    } = authData;

    const [{ email }, handleInputChange] = useForm({
        email: ''
    });

    const [errorUser, setErrorUser] = useState({
        isError: false,
        messageError: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email) {
            setErrorUser({
                isError: true,
                messageError: 'Debes ingresar un email'
            });
            return;
        }
        else if (!emailRegex.test(email)) {
            setErrorUser({
                isError: true,
                messageError: 'El email ingresado no es valido'
            });
            return;
        }
        else {
            setErrorUser({
                isError: false,
                messageError: ''
            });
        }

        handleRecoverPassword(email);
        sendNotification({ msg: 'La contraseña temporal se envio al correo electronico ingresado', variant: 'info' });
        setTimeout(() => {
            navigate('/');
        }, 2000);
    }

    const handleClickBack = (e) => {
        navigate(-1)
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
                marginBottom="1.4rem"
            >
                <ArrowBackRounded
                    className="pointer"
                    fontSize="small"
                    color="text"
                    onClick={handleClickBack}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ mb: 2 }}
            >
                <LockReset color="primary" sx={{ fontSize: 60 }} />
                <Divider
                    orientation="horizontal"
                    flexItem
                >
                    <Typography
                        color="primary"
                        variant="h6"
                    >
                        Recover Password
                    </Typography>
                </Divider>
            </Box>

            <form onSubmit={handleSubmit}>
                <FormGroup sx={{ my: 2 }}>
                    <TextField
                        error={errorUser.isError}
                        color={(errorUser.isError) ? "error" : "text"}
                        label="Email"
                        variant="outlined"
                        name="email"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Mail color={(errorUser.isError) ? "error" : "text"} />
                                </InputAdornment>
                            ),
                        }}
                        value={email}
                        helperText={errorUser.messageError}
                        onChange={handleInputChange}
                    />
                </FormGroup>

                <LoadingButton
                    loading={creatingNewPassword}
                    variant="contained"
                    type="submit"
                    size="small"
                    fullWidth
                    style={{ marginTop: "2rem" }}
                >
                    Recover
                </LoadingButton>
            </form>
        </>
    )
}