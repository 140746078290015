import axios from 'axios';

const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;
const baseUrlFlow = process.env.REACT_APP_AWS_API_GATEWAY_URL_EXECUTE_FLOW;

export const fetchDataDocs = (accessToken, variables, config) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            'process': config?.process_action ? config.process_action : 'reception_select',
            'action': config?.name_action ? config.name_action : 'SELECT',
            'variables': variables
        })
    })
}

export const fetchHistory = (accessToken, id) => {
    return axios({
        method: 'POST',
        url: baseUrl,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        data: JSON.stringify({
            "process": "reception_flow_select",
            "action": "SELECT",
            "variables": `{\"id_reception\":${id}}`
        })
    })
}