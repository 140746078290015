import React, { useEffect, useState, useCallback } from 'react';
import {
    Tooltip, IconButton, Dialog, DialogTitle, Typography, DialogContent, Table, TableHead, TableBody, TableCell, TableRow, Grid, Divider, Link
} from '@mui/material';
import { Close, TableRows } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { JsonEditor } from '../jsonEditorV2/modalEditor/components/editors/JsonEditor'

export const TableParser = ({ data, disabled, onChange }) => {
    const [dataPropertys, setDataPropertys] = useState([]);
    const [propertyPath, setPropertyPath] = useState('');
    const [open, setOpen] = useState(false);

    const findKeyWithValue = useCallback((dto, targetValue, currentPath, keyParent) => {
        try {
            if (Array.isArray(dto)) {
                for (let i = 0; i < dto.length; i++) {
                    const newPath = `${currentPath}[${i}]`;
                    findKeyWithValue(dto[i], targetValue, newPath, keyParent);
                }
            }
            else if (typeof dto === 'object' && dto !== null) {
                for (const key in dto) {
                    if (dto.hasOwnProperty(key)) {
                        const newPath = `${currentPath}.${key}`;
                        findKeyWithValue(dto[key], targetValue, newPath, key);
                    }
                }
            }
            else {
                if (keyParent === targetValue) {
                    let curent = currentPath.replace(`.${keyParent}`, '');
                    const dataFields = dto.split('+');
                    for (const dataField of dataFields) {
                        setDataPropertys(prevData => [...prevData, (
                            {
                                /* eslint-disable */
                                'functions': getProperty(data, curent.replace(/[\[\]]/g, '')),
                                'name': curent.replace(/[\[\]]/g, ''),
                                /* eslint-enable */
                                'value': dataField
                            }
                        )]);
                    }
                }
            }
        } catch (ex) {
            console.log('ex', ex);
        }
    }, [data]);

    useEffect(() => {
        setDataPropertys([]);
        findKeyWithValue(data, 'field_value', '', 'Root');
    }, [data, findKeyWithValue])

    const getProperty = (obj, path) => {
        const property = path.split('.').reduce((acc, part) => acc && acc[part], obj);
        return property?.functions;
    }


    const LoadFunctions = (dto) => {
        return (
            <>
                {
                    dto && dto.functions && (
                        dto.functions.map((item) => (
                            <Typography>{item.name}</Typography>
                        ))
                    )
                }
            </>
        )
    }

    return (
        <React.Fragment>
            <Tooltip title={'Ver registros en tabla'}>
                <LoadingButton
                    size='small'
                    sx={{ p: 0 }}
                    color='warning'
                    onClick={() => setOpen(true)}
                    disabled={disabled}
                >
                    <TableRows />
                </LoadingButton>
            </Tooltip>

            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                PaperProps={{
                    style: { height: '80%' },
                }}
            >
                <DialogTitle>
                    <Typography variant='h5' component='h5'>
                        Historico del documento
                    </Typography>

                    <IconButton
                        aria-label='close'
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        item
                        style={{ display: 'flex', alignItems: 'stretch' }}
                    >
                        <Grid
                            item
                            md={6}
                            elevation={4}
                            sx={{
                                bgcolor: (theme) => theme.palette.paper.background
                            }}
                            style={{ overflow: 'auto', height: 400, flex: 1 }}
                        >
                            <Table
                                aria-labelledby='tableTitle'
                                size='small'
                                stickyHeader
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>field_value</TableCell>
                                        <TableCell>functions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataPropertys.length > 0 && (
                                            dataPropertys.map((item) => (
                                                <TableRow>
                                                    <Link
                                                        sx={{ cursor: 'pointer' }}
                                                        color='text.primary'
                                                        onClick={() => setPropertyPath(item.name)}
                                                    >
                                                        <TableCell>{item.name}</TableCell>
                                                    </Link>
                                                    <TableCell>{item.value}</TableCell>
                                                    <TableCell>
                                                        {LoadFunctions(item)}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                        <Divider style={{ width: 2, backgroundColor: 'black', margin: 15 }} />
                        <Grid
                            item
                            md={6}
                            elevation={4}
                            sx={{
                                bgcolor: (theme) => theme.palette.paper.background
                            }}
                            style={{ overflow: 'auto', height: 450, flex: 1 }}
                        >
                            <JsonEditor value={data} onChange={onChange} propertyPath={propertyPath} />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}