import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    Box, darken, TablePagination, TableCell, TableHead as MuiTableHead, TableRow, Typography, TableContainer, Table, Breadcrumbs, Link, TableBody, Paper, Tooltip, CircularProgress
} from '@mui/material';
import styled from '@emotion/styled';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useManageDocQuery } from '../../../hooks/useManageDocQuery';
import { getAllDocs } from '../../../redux/actions/docQuery';
import { LoadButtons } from '../../../components/loadActions/LoadButtons';
import { FilterTable } from '../../../components/others/FilterTable';
import { useNotification } from '../../../hooks/useNotification';
import axios from 'axios';
import IconExcel from '../../../assets/images/IconExcel.svg';

const TableHead = styled(MuiTableHead)(({ theme }) => ({
    '& .css-x6proc-MuiTableCell-root': {
        backgroundColor: '#b4b4b4',
        borderRight: `thin dotted   ${darken(theme.palette.text.main, 0.8)}`,
    },
    '& .MuiTableCell-root': {
        borderRight: `thin dotted   ${darken(theme.palette.text.main, 0.8)}`,
    },
    color: theme.palette.primary.main,
    '& .MuiTableCell-root span': {
        fontWeight: 'bold',
        '&.Mui-inactive, .MuiTableSortLabel-icon ': {
            color: theme.palette.text.main,
            opacity: 0.2,
        },
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    '& .MuiTableCell-root span.MuiSvgIcon-root:hover': {
        color: theme.palette.text.main,
    }
}));

export const LayoutDocQuery = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [sendNotification] = useNotification();
    const [columns, setColums] = useState([]);
    const [configuration, setConfiguration] = useState(null);
    const [isFirstLoadDone, setIsFirstLoadDone] = useState(true);
    let [conf, setConf] = useState({});
    const [{ userSession }] = useDataAuth();
    const [filters] = useState({});
    const [pagination] = useState(
        {
            pageNumber: 1,
            pageSize: 25
        }
    );
    const [DocManager] = useManageDocQuery();
    const {
        listAllDocs,
        isLoading
    } = DocManager;

    const GetAllDocs = useCallback((variables) => {
        dispatch(getAllDocs(userSession.token, variables, conf));
    }, [dispatch, userSession.token, conf]);

    useEffect(() => {
        if (location.state && location.state.configuration) {
            const { configuration, title, config } = location.state || {};
            setConf({ 'process_action': config.process_action, 'name_action': config.name_action });
            setColums(configuration ? JSON.parse(configuration)?.table : []);
            setConfiguration({ configuration, title });
            setIsFirstLoadDone(false);
        }
    }, [location.state]);

    useEffect(() => {
        if (!isFirstLoadDone)
            GetAllDocs(JSON.stringify({ filters, pagination }));
    }, [GetAllDocs, filters, pagination, isFirstLoadDone]);

    const handleChangePage = (event, newPage) => {
        pagination['pageNumber'] = newPage + 1;
        GetAllDocs(JSON.stringify({ filters, pagination }));
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        pagination['pageNumber'] = 1;
        pagination['pageSize'] = newRowsPerPage;
        GetAllDocs(JSON.stringify({ filters, pagination }));
    };

    const Search = (param, param2) => {
        filters[param2] = param;
        pagination['pageNumber'] = 1;
        GetAllDocs(JSON.stringify({ filters, pagination }));
    }

    const ResetSearch = (param) => {
        delete filters[param];
        GetAllDocs(JSON.stringify({ filters, pagination }));
    }

    const GenerateReport = async () => {
        let header = {};
        columns.forEach((col) => {
            if (col.visible) {
                header[col.label] = `{${col.id}}`;
            }
        });
        const bodyData = {
            'id_flow': '9',//valor quemado
            'process': conf.process_action,
            'action': conf.name_action,
            'variables': JSON.stringify({ filters }),
            'schema': header
        }

        const baseUrlFlow = process.env.REACT_APP_AWS_API_GATEWAY_URL_EXECUTE_FLOW;
        let config = {
            method: 'POST',
            url: baseUrlFlow,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userSession.token}`
            },
            data: JSON.stringify(bodyData)
        };

        try {
            const response = await axios.request(config);
            if (response.status === 200) {
                const byteCharacters = atob(response.data);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                let yourDate = new Date()
                link.setAttribute('download', configuration.title + yourDate.toISOString().split('T')[0] + '.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else if (response.status === 408)
                sendNotification({ msg: 'Evento realizado correctamente!', variant: 'success' });
            else
                console.error('R_ ', response.status);
        } catch (error) {
            console.error('error _', error);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ mb: 2, pt: 2, pl: 2, pr: 2 }}>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                    <Breadcrumbs
                        aria-label='breadcrumb'
                        separator='›'
                        sx={{ mb: 2 }}
                    >
                        <Link
                            underline='hover'
                            color='text.main'
                            sx={{ cursor: 'pointer' }}
                        >
                            <Typography
                                sx={{ fontWeight: 'bold' }}
                            >
                                {configuration?.title ? configuration.title : 'Consultar documentos'}
                            </Typography>
                        </Link>
                    </Breadcrumbs>
                    <Tooltip title={'Exportar datos a Excel'}>
                        <img
                            src={IconExcel}
                            alt='Icon/Excel'
                            sx={{
                                width: 30,
                                height: 'auto',
                            }}
                            onClick={() => GenerateReport()}
                        />
                    </Tooltip>
                </Box>

                <Box sx={{ width: '100%' }}>
                    <Paper
                        sx={{
                            width: '100%',
                            mb: 2,
                            boxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                            WebkitBoxShadow: (theme) => theme.palette.dark === 'dark' ? '0px 0px 8px 0px rgba(0,0,0,0.75)' : '0px 0px 8px 0px rgba(0,0,0,0.25)',
                            bgcolor: (theme) => theme.palette.paper.background
                        }}
                        elevation={3}
                    >
                        <TableContainer
                            style={{ height: '72vh', overflow: 'auto', borderBottom: 'solid 1px' }}
                        >
                            <Table
                                aria-labelledby='tableTitle'
                                size='small'
                                stickyHeader
                            >
                                <TableHead>
                                    <TableRow sx={{ px: 2 }}>
                                        <TableCell
                                            padding='normal'
                                            align='center'
                                            style={{ minWidth: 120 }}
                                        >
                                            <Typography sx={{ fontWeight: 'bold' }} component='span'>
                                                Actions
                                            </Typography>
                                        </TableCell>
                                        {
                                            columns.map((col, index) => (
                                                col.visible &&
                                                <TableCell
                                                    padding='normal'
                                                    align='center'
                                                    key={`tc-${index}`}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Typography sx={{ fontWeight: 'bold', marginRight: '8px' }} component='span'>
                                                            {col.label}
                                                        </Typography>
                                                        {col.filterable && (
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <FilterTable colum={col} Search={Search} ResetSearch={ResetSearch} />
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        isLoading ? (
                                            <TableRow sx={{ height: 200, py: 2 }}>
                                                <TableCell
                                                    colSpan={columns.length + 2}
                                                    align='center'
                                                >
                                                    <CircularProgress
                                                        color='primary'
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ) : !isLoading && !listAllDocs.length > 0 ? (
                                            <TableRow sx={{ height: 200, py: 2 }}>
                                                <TableCell
                                                    colSpan={columns.length + 2}
                                                    align='center'
                                                >
                                                    <Typography>No hay registros para mostrar</Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            listAllDocs.map((item) => (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={item.id}
                                                >
                                                    <TableCell padding='normal' align='center'>
                                                        <Typography>
                                                            <LoadButtons data={item} handleChange={GetAllDocs} />
                                                        </Typography>
                                                    </TableCell>
                                                    {columns.map((col, index) => (
                                                        col.visible &&
                                                        <TableCell
                                                            key={`tc-${index}`}
                                                            padding='normal'
                                                            align='center'
                                                        >
                                                            <Typography>
                                                                {item[col.id]}
                                                            </Typography>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            sx={{ display: 'table-row', verticalAlign: 'bottom' }}
                            rowsPerPageOptions={[25, 50, 100]}
                            component='Box'
                            count={10000}
                            rowsPerPage={pagination.pageSize}
                            page={pagination.pageNumber - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}