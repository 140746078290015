import { Box, Grid, Divider, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { ListDragDrop } from '../../../components/lists/ListDragDrop';
import { useDataAuth } from '../../../hooks/useDataAuth';
import { useNotification } from '../../../hooks/useNotification';
import { useDispatch } from 'react-redux';
import { changeFlowInEdition, getSchemes } from '../../../redux/actions/flows';
import { LoadingButton } from '@mui/lab';
import { Add, DataObject, DynamicForm as DynamicFormIcon } from '@mui/icons-material';
import { EditItemToolbar } from '../../../components/editItem/EditItemToolbar';
import { TableParser } from '../../../components/tables/TableParser';
import { JsonEditor } from '../../../components/jsonEditorV2/modalEditor/components/editors/JsonEditor';
import DynamicForm from '../../../components/dynamicForm/DynamicForm';
import { config } from '../../../../src/helpers/config';

export const DetailsFlow = ({ flowManager, statusManager, updateFlow }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [flowSelected, setFlowSelected] = useState({});
    const [sendNotification] = useNotification();
    const params = useParams();
    const {
        updatingFlows: isUpdatingFlows,
        deletingFlows: isDeletingFlows,
        listFlows,
        listSchemes,
        isChangeDataFlows,
        handleDeleteFlows,
        handleChangeFlow,
        handleUpdatedFlow,
        handleAddComponentFlow,
        handleDeleteComponentFlow,
        setCurrentBreadCrumb,
        setIsChangeDataFlows
    } = flowManager;

    const {
        listStatus,
        loadingStatus
    } = statusManager;

    const [isLoadingFlows, setIsLoadingFlows] = useState(false);
    const [{ username, userSession }] = useDataAuth();

    useEffect(() => {
        setIsLoadingFlows(true);
        setTimeout(() => {
            const flowSelected = listFlows.find(flow => flow.id_flow === parseInt(params.idFlow));
            setFlowSelected(flowSelected);
            dispatch(changeFlowInEdition(flowSelected));
            setIsLoadingFlows(false);
            setCurrentBreadCrumb(1);
        }, 500);
    }, []);

    useEffect(() => {
        const flowSelected = listFlows.find(flow => flow.id_flow === parseInt(params.idFlow));
        setFlowSelected(flowSelected);
    }, [listFlows]);

    const handleClickDeleteFlow = () => {
        handleDeleteFlows(
            [!isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow]
        )
        setTimeout(() => {
            navigate(`/flows/`)
        }, 500);
    }

    const handleClickNewComponentFlow = () => {
        const idFlowComponent = `NC-${flowSelected?.flow_components ? flowSelected?.flow_components?.length + 1 : 1}`;

        const newFlowComponentObject = {
            is_new_component: true,
            id_flow_component: idFlowComponent,
            type: "",
            order_in_flow: flowSelected?.flow_components ? flowSelected?.flow_components?.length + 1 : 1,
            order_in_flow_component: 0,
            type_execute: "",
            process_input: {},
            acceptance_process: {},
            process_fail: {},
            created: moment().toDate(),
            created_by: username,
            updated: moment().toDate(),
            updated_by: username,
            sub_flow_components: []
        };
        handleAddComponentFlow(
            parseInt(params.idFlow),
            newFlowComponentObject
        );
        navigate(`/flows/flow-${params.idFlow}/component-flow-${idFlowComponent}`)
    }

    const handleChangeOrderComponentFlows = (newArrayComponentFlows) => {
        handleChangeFlow(
            !isNaN(params.idFlow) ? parseInt(params.idFlow) : params.idFlow,
            'flow_components',
            newArrayComponentFlows
        );
    }

    const [contentJson, setContentJson] = useState({});
    const [Json, setJson] = useState({});
    const [identifier, setIdentifier] = useState();
    const [jsonOrForm, setJsonOrForm] = useState(false);
    const [isParser, setIsParser] = useState(false);
    const [itemEdit, setItemEdit] = useState({});
    const [scheme, setEscheme] = useState([]);

    const setItem = (item) => {
        setIsParser(item.type_component === 'parser');
        setItemEdit(item);
        setContentJson(item.body);
        setJson(item.body);
        setIdentifier(item.order);
        console.log('find', listSchemes.find(s => s.type_component === item.type_component));
        let sch = JSON.parse(listSchemes.find(s => s.type_component === item.type_component)?.json_schema)
        console.log('sch', sch);
        setEscheme(sch ? sch : []);
        setJsonOrForm(sch ? true : false);
    }

    const SetNewValue = (data) => {
        setContentJson(data);
        if (Json !== data)
            setIsChangeDataFlows(true);
    }

    const Update = () => {
        const flowC = JSON.parse(flowSelected.component).flow_component;
        const index = JSON.parse(flowSelected.component).flow_component.findIndex(p => p.order === identifier);
        JSON.parse(flowSelected.component).flow_component[index].body = contentJson;
        flowC[index].body = contentJson;
        JSON.parse(flowSelected.component).flow_component = flowC;
        const data = JSON.stringify({ 'id_flow': flowSelected.id_flow, 'component': { 'flow_component': flowC } });
        updateFlow(data);
    }

    useEffect(() => {
        dispatch(getSchemes(userSession.token));
    }, []);

    const onChange = (e) => {
        const { name, value, type, checked } = e.target;
        setContentJson({ ...contentJson, [name]: type === 'switch' ? checked : value });
        if (Json !== contentJson)
            setIsChangeDataFlows(true);
    }

    const SetChanges = (key, value) => {
        setContentJson({ ...contentJson, [key]: value });
        setTimeout(() => {
            if (Json !== contentJson)
                setIsChangeDataFlows(true);
        }, 500);
    }

    return (
        <Box>
            <Grid container sx={{ mb: 2 }} >
                <EditItemToolbar
                    isLoadingItems={isLoadingFlows}
                    titleEditItem={{
                        label: `Id Flow: ${flowSelected.id_flow} / `,
                        name: 'name',
                        value: flowSelected.name,
                        handleChange: handleChangeFlow,
                        isEditable: true
                    }}
                    nameItem={"Flow"}
                    isSaving={isUpdatingFlows}
                    statusButtonSave={isChangeDataFlows}
                    isDeleting={isDeletingFlows}
                    // handleSaveChanges={handleClickSaveChanges}
                    handleSaveChanges={() => Update()}
                    statusButtonDelete={true}
                    handleClickDelete={handleClickDeleteFlow}
                />
            </Grid>
            <Grid
                container
                item
                style={{ display: "flex", alignItems: "stretch" }}
            >
                <Grid
                    item
                    md={6}
                    elevation={4}
                    sx={{
                        bgcolor: (theme) => theme.palette.paper.background
                    }}
                    style={{ overflow: 'auto', height: 400, padding: 20, flex: 1 }}
                >
                    <Box>
                        {/* <Box
                            display="flex"
                            sx={{ mb: 2, position: 'sticky', top: 0, zIndex: 1 }}
                            justifyContent="flex-end"
                        >
                            <LoadingButton
                                loading={false}
                                loadingPosition="start"
                                startIcon={<Add />}
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={handleClickNewComponentFlow}
                            >
                                Create new
                            </LoadingButton>
                        </Box> */}
                        <Box>
                            <ListDragDrop
                                numberGridList={6}
                                nameItem="component flow"
                                keyItem="componentFlow"
                                keyItemOrder="order_in_flow"
                                flowSelected={flowSelected}
                                listItems={flowSelected?.component ? JSON.parse(flowSelected.component).flow_component : []}
                                handleDeleteComponentFlow={handleDeleteComponentFlow}
                                handleChangeOrderItems={handleChangeOrderComponentFlows}
                                setItem={setItem}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Divider style={{ width: 2, backgroundColor: 'black', margin: 15 }} />
                <Grid
                    item
                    md={6}
                    elevation={4}
                    sx={{
                        bgcolor: (theme) => theme.palette.paper.background
                    }}
                    style={{ overflow: 'auto', height: 400, padding: 20, flex: 1 }}
                // style={{ padding: 20, flex: 1 }}
                >
                    <Box display="flex" justifyContent="space-between">
                        <Box
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}
                        >
                            <h3>{itemEdit.description}</h3>
                        </Box>
                        <Box
                            display="flex"
                            sx={{ mb: 2, position: 'sticky', top: 0, zIndex: 1 }}
                            justifyContent="flex-end"
                        >
                            <Tooltip title='Ver json'>
                                <LoadingButton
                                    color="success"
                                    onClick={() => setJsonOrForm(false)}
                                >
                                    <DataObject />
                                </LoadingButton>
                            </Tooltip>

                            <Tooltip title='Ver formulario'>
                                <LoadingButton
                                    color="info"
                                    onClick={() => setJsonOrForm(true)}
                                    disabled={scheme && scheme.length > 0 ? false : true}
                                >
                                    <DynamicFormIcon />
                                </LoadingButton>
                            </Tooltip>

                            <TableParser data={contentJson} disabled={!isParser} onChange={SetNewValue} />
                        </Box>
                    </Box>

                    {jsonOrForm ?
                        <DynamicForm
                            values={contentJson}
                            scheme={scheme}
                            onChange={onChange}
                            setChanges={SetChanges}
                        />
                        :
                        // <JSONTextEditor value={contentJson} onChange={SetNewValue} />
                        <JsonEditor
                            value={contentJson}
                            onChange={SetNewValue}
                        />
                    }
                </Grid>
            </Grid>

        </Box>
    )
}
