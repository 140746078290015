import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Box, Divider, FormGroup, IconButton, InputAdornment, TextField, Typography, Link } from '@mui/material';
import { AccountCircleRounded, Password, Person, Visibility, VisibilityOff } from '@mui/icons-material';
import { useFocusInputs } from '../../../hooks/useFocusInputs';
import { useErrorInputs } from '../../../hooks/useErrorInputs';
import { useForm } from '../../../hooks/useForm';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { SignInButton } from '../../../components/ADAzure/SignInButton';
import { useNotification } from '../../../hooks/useNotification';
import { setNotificaction } from '../../../redux/actions/auth';

export const FormAuthV2 = ({ authData }) => {
    const navigate = useNavigate();
    const [sendNotification] = useNotification();
    const dispatch = useDispatch();

    const {
        checkingLogin,
        errorLogin: errorLogging,
        handleLogin,
        isNotification,
        msg,
        typeMsg,
    } = authData;
    const [showPassword, setShowPassword] = useState(false);

    const [{ username, password }, handleInputChange] = useForm({
        username: '',
        password: ''
    });
    const [{ isUsernameFocus, isPasswordFocus }, handleFocusInputs] = useFocusInputs({
        isUsernameFocus: false,
        isPasswordFocus: false
    });
    const [{ usernameError, passwordError }, handleErrorInput] = useErrorInputs({
        usernameError: {
            isError: false,
            messageError: ''
        },
        passwordError: {
            isError: false,
            messageError: ''
        },
    });

    const handleFocus = (e) => {
        handleFocusInputs(e);
        handleErrorInput(`${e.target.name}Error`, {
            isError: false,
            messageError: ''
        });
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleSubmit = (e) => {
        e.preventDefault()

        if (username.length <= 0) {
            handleErrorInput('usuarioError', {
                isError: true,
                messageError: 'The field username is required'
            });
            return;
        }

        if (password.length <= 0) {
            handleErrorInput('passwordError', {
                isError: true,
                messageError: 'The field password is required'
            });
            return;
        }
        handleLogin(username, password);
    }

    const handleSubmitRecoverPassword = () => {
        navigate('/login/recoverPassword')
    }

    const [host, setHost] = useState('');
    useEffect(() => {
        let hostName = window.location.hostname;
        setHost(hostName);
    }, [setHost])

    useEffect(() => {
        if (isNotification) {
            sendNotification({ msg: msg, variant: typeMsg });
            dispatch(setNotificaction({}));
        }
    }, [isNotification, msg, typeMsg, dispatch, sendNotification]);


    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ mb: 2 }}
            >
                <AccountCircleRounded color="primary" sx={{ fontSize: 60 }} />
                <Divider
                    orientation="horizontal"
                    flexItem
                >
                    <Typography
                        color="primary"
                        variant="h6"
                    >
                        Login
                    </Typography>
                </Divider>
            </Box>


            {(errorLogging) &&
                <Alert severity="error">{errorLogging}</Alert>
            }

            <form onSubmit={handleSubmit} method="POST">
                <FormGroup
                    sx={{ my: 2 }}
                >
                    <TextField
                        error={!isUsernameFocus && usernameError.isError}
                        color={(isUsernameFocus) ? "text" : (usernameError.isError) ? "error" : "text"}
                        label="Username"
                        variant="outlined"
                        name="username"
                        placeholder="Enter username"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person color={(isUsernameFocus) ? "text" : (usernameError.isError) ? "error" : "text"} />
                                </InputAdornment>
                            ),
                        }}
                        value={username}
                        helperText={usernameError.messageError}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                    />
                </FormGroup>

                <FormGroup>
                    <TextField
                        error={!isPasswordFocus && passwordError.isError}
                        color={(isPasswordFocus) ? "text" : (passwordError.isError) ? "error" : "text"}
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        variant="outlined"
                        name="password"
                        placeholder="Enter password"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Password color={(isPasswordFocus) ? "text" : (passwordError.isError) ? "error" : "text"} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <IconButton
                                    color={(isPasswordFocus) ? "text" : (passwordError.isError) ? "error" : "text"}
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            )
                        }}

                        value={password}
                        helperText={passwordError.messageError}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                    />
                </FormGroup>

                <LoadingButton
                    loading={checkingLogin}
                    variant="contained"
                    type="submit"
                    size="small"
                    fullWidth
                    style={{ marginTop: "2rem" }}
                >
                    Login
                </LoadingButton>
            </form>

            {
                host === 'gemm.iaautomation.com' &&
                <SignInButton callRegularLogin={handleLogin} />
            }

            <Box
                sx={{ marginTop: 1 }}
                display='flex'
                justifyContent='flex-end'
            >
                <Link
                    underline='hover'
                    color='text.main'
                    sx={{ cursor: 'pointer', }}
                    onClick={() => handleSubmitRecoverPassword()}
                >
                    <Typography
                        sx={{ fontStyle: 'italic', fontSize: 12 }}
                    >
                        Olvidé mi contraseña
                    </Typography>
                </Link>
            </Box>
        </>
    )
}
