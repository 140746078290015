import { ArrowBackRounded, LockOpen, Password } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab';
import { Box, Divider, FormGroup, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useErrorInputs } from '../../../hooks/useErrorInputs';
import { useFocusInputs } from '../../../hooks/useFocusInputs';
import { useForm } from '../../../hooks/useForm';
import { useNotification } from '../../../hooks/useNotification';

export const FormNewPassword = ({ authData }) => {
    const [sendNotification] = useNotification();
    const navigate = useNavigate();
    const {
        creatingNewPassword,
        handleCreateNewPassword,
        tokenTemporal
    } = authData;

    const [{ newPassword, confirmNewPassword }, handleInputChange] = useForm({
        newPassword: '',
        confirmNewPassword: ''
    });
    const [{ isNewPasswordFocus, isConfirmNewPasswordFocus }, handleFocusInputs] = useFocusInputs({
        isNewPasswordFocus: false,
        isConfirmNewPasswordFocus: false
    });

    const [errorNewPasword, setErrorNewPasword] = useState({
        isError: false,
        messageError: ''
    });
    const [errorConfirmPasword, setErrorConfirmPasword] = useState({
        isError: false,
        messageError: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        //LA PASSWORD DEBE TENER UNA LONGITUD MINIMA DE 8 CARACTERES, DEBE CONTENER LO SIGUIENTE:
        //UNA MINUSCULA a-z, UNA MAYUSCULA A-Z, UN NUMERO 0-9, UNO DE LOS SIGUIENTES CARACTERES $ @ $ ! % * ? & +.
        const regex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&+])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/);
        if (!newPassword) {
            setErrorNewPasword({
                isError: true,
                messageError: 'Debes ingresar un password'
            });
            return;
        }
        else if (!regex.test(newPassword)) {
            sendNotification({ msg: 'La password debe al menos contener una letra minúscula, una mayúscula, un número y un carácter especial de la lista [$@$!%*?&+]', variant: 'info' });
            setErrorNewPasword({
                isError: true,
                messageError: 'La password no cumple con los requerimientos'
            });
            return;
        }
        else {
            setErrorNewPasword({
                isError: false,
                messageError: ''
            })
        }

        if (newPassword !== confirmNewPassword) {
            setErrorConfirmPasword({
                isError: true,
                messageError: 'Los password no coinciden'
            });
            return;
        }
        else
            setErrorConfirmPasword({
                isError: false,
                messageError: ''
            })
        handleCreateNewPassword(tokenTemporal, newPassword);
        sendNotification({ msg: 'Contraña actualizada con exito!', variant: 'info' });
        setTimeout(() => {
            console.log('Navigate');
            handleClickBack({});
        }, 2000);
    }

    const handleFocus = (e) => {
        handleFocusInputs(e);
    }

    const handleClickBack = (e) => {
        navigate(-1)
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexDirection="row"
                marginBottom="1.4rem"
            >
                <ArrowBackRounded
                    className="pointer"
                    fontSize="small"
                    color="text"
                    onClick={handleClickBack}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ mb: 2 }}
            >
                <LockOpen color="primary" sx={{ fontSize: 60 }} />
                <Divider
                    orientation="horizontal"
                    flexItem
                >
                    <Typography
                        color="primary"
                        variant="h6"
                    >
                        Create New Password
                    </Typography>
                </Divider>
            </Box>
            {/* <input type='button' value={'PrintTokenTemp'} onClick={() => console.log('TokenTmp => ', tokenTemporal)} /> */}

            <form onSubmit={handleSubmit}>
                <FormGroup sx={{ my: 2 }}>
                    <TextField
                        error={errorNewPasword.isError}
                        color={(isNewPasswordFocus) ? "text" : (errorNewPasword.isError) ? "error" : "text"}
                        label="New Password"
                        type={'password'}
                        variant="outlined"
                        name="newPassword"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Password color={(isNewPasswordFocus) ? "text" : (errorNewPasword.isError) ? "error" : "text"} />
                                </InputAdornment>
                            )
                        }}
                        value={newPassword}
                        helperText={errorNewPasword.messageError}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                    />
                </FormGroup>
                <FormGroup>
                    <TextField
                        error={errorConfirmPasword.isError}
                        color={(isConfirmNewPasswordFocus) ? "text" : (errorConfirmPasword.isError) ? "error" : "text"}
                        label="Confirm New Password"
                        type={'password'}
                        variant="outlined"
                        name="confirmNewPassword"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Password color={(isConfirmNewPasswordFocus) ? "text" : (errorConfirmPasword.isError) ? "error" : "text"} />
                                </InputAdornment>
                            )
                        }}
                        value={confirmNewPassword}
                        helperText={errorConfirmPasword.messageError}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                    />
                </FormGroup>

                <LoadingButton
                    loading={creatingNewPassword}
                    variant="contained"
                    type="submit"
                    size="small"
                    fullWidth
                    style={{ marginTop: "2rem" }}
                >
                    Create new password
                </LoadingButton>
            </form>
        </>
    )
}