import React from 'react';
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export const Dialog = ({
    openDialog,
    widthDialog,
    styleDialog,
    title,
    styleTitle,
    content,
    styleContent,
    actions,
    styleActions
}) => {
    
    return (
        <>
            <MuiDialog
                sx={{ '& .MuiDialog-paper': styleDialog }}
                maxWidth={widthDialog}
                open={openDialog}
            >
                {
                    title &&
                    <DialogTitle sx={styleTitle}>
                        {title}
                    </DialogTitle>
                }
                <DialogContent sx={styleContent}>
                    {content}
                </DialogContent>
                <DialogActions sx={styleActions}>
                    {actions}
                </DialogActions>
            </MuiDialog>
        </>
    )
}
