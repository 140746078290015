export const colTablePermission = [
    {
        "id": "id_user_access",
        "label": "id_user_access",
        "type": "number",
        "filterable": false,
        "visible": false
    },
    {
        "id": "name",
        "label": "Permiso",
        "type": "string",
        "filterable": false,
        "visible": true
    },
    {
        "id": "company_name",
        "label": "Empresa",
        "type": "string",
        "filterable": false,
        "visible": true
    },
    {
        "id": "permission_type",
        "label": "Tipo permiso",
        "type": "string",
        "filterable": true,
        "visible": true
    },
    {
        "id": "type_action",
        "label": "Tipo acción",
        "type": "string",
        "filterable": true,
        "visible": false
    }
]

export const colTableUsers = [
    {
        "id": "id_user",
        "label": "id_user",
        "type": "number",
        "filterable": false,
        "visible": false
    },
    {
        "id": "user_name",
        "label": "user_name",
        "type": "string",
        "filterable": true,
        "visible": true
    },
    {
        "id": "name",
        "label": "name",
        "type": "string",
        "filterable": true,
        "visible": true
    },
    {
        "id": "last_name",
        "label": "last_name",
        "type": "string",
        "filterable": true,
        "visible": true
    },
    {
        "id": "status",
        "label": "status",
        "type": "boolean",
        "filterable": true,
        "visible": true
    },
    {
        "id": "updated",
        "label": "updated",
        "type": "date",
        "filterable": false,
        "visible": true
    }
]