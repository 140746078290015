import { actionTypes } from "../contants/actionTypes";

const initialState = {
    loadingAllFlows: false,
    updatingFlows: false,
    deletingFlows: false,
    deletingComponentFlow: false,
    addingNewFlow: false,
    flowInEdition: {},
    listSchemes: [],
    componentFlowInEdition: {},
    subComponentFlowInEdition: {},
    isChangesPendingFlow: false,
    isChangesPendingComponentFlow: false,
    isChangesPendingSubComponentFlow: false,
    listAllFlows: [],
    loadingExecutions: false,
    listExecutionsFlow: [],
    isErrorFetch: false,
    messageErrorFetch: ''
}

export const flowReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.floStartLoadingAllFlows:
            return {
                ...state,
                loadingAllFlows: true,
            }
        case actionTypes.floFinishLoadingAllFlows:
            return {
                ...state,
                loadingAllFlows: false,
            }
        case actionTypes.floLoadAllFlows:
            return {
                ...state,
                listAllFlows: action.payload,
                error: false
            }
        case actionTypes.floStartUpdatingFlow:
            return {
                ...state,
                updatingFlows: true,
            }
        case actionTypes.floFinishUpdatingFlow:
            return {
                ...state,
                updatingFlows: false,
            }
        case actionTypes.floStartAddingNewFlow:
            return {
                ...state,
                addaddingNewFlowing: true,
            }
        case actionTypes.floFinishAddingNewFlow:
            return {
                ...state,
                addingNewFlow: false,
            }
        case actionTypes.floStartDeletingFlows:
            return {
                ...state,
                deletingFlows: true,
            }
        case actionTypes.floFinishDeletingFlows:
            return {
                ...state,
                deletingFlows: false,
            }

        case actionTypes.floStartDeletingComponentFlow:
            return {
                ...state,
                deletingComponentFlow: true,
            }
        case actionTypes.floFinishDeletingComponentFlow:
            return {
                ...state,
                deletingComponentFlow: false,
            }
        //EditionFlows
        case actionTypes.floChangeFlowInEdition:
            return {
                ...state,
                flowInEdition: action.payload,
            }
        case actionTypes.floChangeComponentFlowInEdition:
            return {
                ...state,
                componentFlowInEdition: action.payload,
            }
        case actionTypes.floChangeSubComponentFlowInEdition:
            return {
                ...state,
                subComponentFlowInEdition: action.payload,
            }

        //IsChangingPending
        case actionTypes.floIsChangesPendingFlow:
            return {
                ...state,
                isChangesPendingFlow: action.payload,
            }
        case actionTypes.floIsChangesPendingComponentFlow:
            return {
                ...state,
                isChangesPendingComponentFlow: action.payload,
            }
        case actionTypes.floIsChangesPendingSubComponentFlow:
            return {
                ...state,
                isChangesPendingSubComponentFlow: action.payload,
            }

        case actionTypes.floStartLoadingExecutionsByFlow:
            return {
                ...state,
                loadingExecutions: true,
            }
        case actionTypes.floFinishLoadingExecutionsByFlow:
            return {
                ...state,
                loadingExecutions: false,
            }
        case actionTypes.floLoadExecutionsByFlow:
            return {
                ...state,
                listExecutionsFlow: action.payload,
                error: false
            }

        case actionTypes.floErrorManageFlows:
            return {
                ...state,
                isErrorFetch: action.payload.error,
                messageErrorFetch: action.payload.messageError
            }
        case actionTypes.GetSchemes:
            return {
                ...state,
                listSchemes: action.payload,
            }
        default:
            return state;
    }

}