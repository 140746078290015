
export const themeDark = {
    palette: {
      mode: 'dark',
      primary: {
        main: "#FF2A2A",
      },
      secondary: {
        main: "#800000",
      },
      third: {
        main: '#800000',
      },
      dark:{
        main: "#121212",
        secondary: "#121212",
        third: '#242a38'
      },
      text: {
        main: '#ffffff',
      },
      navigationBar:{
        main: '#1E1E1E',
        secondary: '#121212',
        text: '#ffffff',
        selected: '#313131',
        hover: '#313131'
      },
      paper: {
        background: '#1E1E1E',
      }
    },
    typography: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: 14,
      display4: {
        fontSize: 17,
      },
      display3: {
        fontSize: 14,
      },
      display2: {
        fontSize: 14,
      },
      display1: {
        fontSize: 14,
      },
      headline: {
        fontSize: 14,
      },
      subheading: {
        fontSize: 14,
      },
      body2: {
        fontSize: 14,
      },
      body1: {
        fontSize: 14,
      },
      button: {
        textTransform: 'none',  
        fontSize: 15,
        fontWeight: 'bold'
      },
      console: {
        fontSize: 14,
        fontFamily: 'consolas',
      }      
    }
};

export const themeLight = {
  palette: {
    mode: 'light',
    primary: {
      main: "#FF2A2A",
    },
    secondary: {
      main: "#800000",
    },
    third: {
      main: '#800000',
    },
    light:{
      main: "#fefefe",
      secondary: "#f0f2f5",
      third: '#242a38'
    },
    text: {
      main: '#0b0f19',
    },
    navigationBar:{
      main: '#1E1E1E',
      secondary: '#121212',
      text: '#ffffff',
      selected: '#313131',
      hover: '#313131'
    },
    paper: {
      background: '#fefefe',
    }
  },
  typography: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    display4: {
      fontSize: 17,
    },
    display3: {
      fontSize: 14,
    },
    display2: {
      fontSize: 14,
    },
    display1: {
      fontSize: 14,
    },
    headline: {
      fontSize: 14,
    },
    subheading: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    button: {
      textTransform: 'none',  
      fontSize: 15,
      fontWeight: 'bold'
    },
    console: {
      fontSize: 14,
      fontFamily: 'consolas',
    }    
  }
}
