export const actionTypes = {
    authStartCheckingSession: '[Auth] Start Checking Session',
    authFinishCheckingSession: '[Auth] Finish Checking Session',
    authLoadSession: '[Auth] Load Session',
    authErrorCheckingSession: '[Auth] Error Checking Session',
    authStartCheckingLogin: '[Auth] Start Checking Login',
    authFinishCheckingLogin: '[Auth] Finish Checking Login',
    authInitializingLogin: '[Auth] Initializing Login',
    authLoadDataLogin: '[Auth] Login',
    authErrorLogging: '[Auth] Error Login',
    authPasswordChangeRequired: '[Auth] Password Change Required',
    authStartCreatingNewPassword: '[Auth] Start Creating New Password',
    authFinishCreatingNewPassword: '[Auth] Finish Creating New Password',
    authLogout: '[Auth] Logout',

    compLoadingStart: '[Comp] Checking Start Companies',
    compLoadingFinish: '[Comp] Checking Finish Companies',
    compGetCompanies: '[Comp] Get Companies',
    compLoadCompanies: '[Comp] Load Companies',
    compSaveCompanySelected: '[Comp] Save Company',

    // floGetAllFlows: '[Flows] Get All Flows',
    floStartLoadingAllFlows: '[Flows] Loading Start All Flows',
    floFinishLoadingAllFlows: '[Flows] Loading Finish All Flows',
    floLoadAllFlows: '[Flows] Load All Flows',
    // floUpdateFlows: '[Flows] Update Flows',
    floStartUpdatingFlow: '[Flows] Start Updating Flows',
    floFinishUpdatingFlow: '[Flows] Finish Updating Flows',
    // floAddNewFlow: '[Flows] Add New Flow',
    floStartAddingNewFlow: '[Flows] Start Adding New Flow',
    floFinishAddingNewFlow: '[Flows] Finish Adding New Flow',
    // floDeleteFlows: '[Flows] Delete Flows',
    floStartDeletingFlows: '[Flows] Start Deleting Flows',
    floFinishDeletingFlows: '[Flows] Finish Deleting  Flows',
    // floDeleteComponentFlow: '[Flows] Delete Component Flow',
    floStartDeletingComponentFlow: '[Flows] Start Deleting Component Flow',
    floFinishDeletingComponentFlow: '[Flows] Finish Deleting Component Flow',
    //ChangesPending
    floIsChangesPendingFlow: '[Flows] Is Changes Pending Flows',
    floIsChangesPendingComponentFlow: '[Flows] Is Changes Pending Component Flows',
    floIsChangesPendingSubComponentFlow: '[Flows] Is Changes Pending Sub Component Flows',
    //FlowsInEdition
    floChangeFlowInEdition: '[Flows] Change Flow In Edition',
    floChangeComponentFlowInEdition: '[Flows] Change Component Flow In Edition',
    floChangeSubComponentFlowInEdition: '[Flows] Change Sub Component Flow In Edition',

    // floGetExecutionsByFlow: '[Flows] GetExecutionsByFlow',
    floStartLoadingExecutionsByFlow: '[Flows] Start Loading Executions By Flow',
    floFinishLoadingExecutionsByFlow: '[Flows] Finish Loading Executions By Flow',
    floLoadExecutionsByFlow: '[Flows] Load Executions By Flow',
    floErrorManageFlows: '[Flows] Error Flows',

    settiStartLoadingSettingsFlows: '[Settings] Start Loading Settings Flows',
    settiFinishLoadingSettingsFlows: '[Settings] Finish Loading Settings Flows',
    settiLoadAllSettingsFlows: '[Settings] Load All Settings Flows',
    settiStartUpdatingSettingsFlows: '[Settings] Start Updating Settings Flows',
    settiFinishUpdatingSettingsFlows: '[Settings] Finish Updating Settings Flows',
    settiStartDeletingSettingsFlow: '[Settings] Start Deleting Settings Flows',
    settiFinishDeletingSettingsFlows: '[Settings] Finish Deleting Settings Flows',
    settiStartAddingSettingsFlows: '[Settings] Start Adding Settings Flow',
    settiFinishAddingSettingsFlow: '[Settings] Finish Adding Settings Flow',
    settiErrorSettingFlows: '[Settings] Error Settings Flows',

    staStartLoadingStatus: '[Status] Start Loading Status',
    staFinishLoadingStatus: '[Status] Finish Loading Status',
    staLoadStatus: '[Status] Load Status',
    staStartUpdatingStatus: '[Status] Start Updating Status',
    staFinishUpdatingStatus: '[Status] Finish Updating Status',
    staStartDeletingStatus: '[Status] Start Deleting Status',
    staFinishDeletingStatus: '[Status] Finish Deleting Status',
    staStartAddingStatus: '[Status] Start Adding Status',
    staFinishAddingStatus: '[Status] Finish Adding Status',
    staErrorStatus: '[Status] Error Status',

    tracStartLoadingStatusAllFlows: '[Traceability] Start Loading Status All Flows',
    tracFinishLoadingStatusAllFlows: '[Traceability] Finish Loading Status All Flows',
    tracLoadStatusAllFlows: '[Traceability] Load Status All Flows',
    tracStartLoadingExecutionsByFlow: '[Traceability] Start Loading Executions By Flow',
    tracFinishLoadingExecutionsByFlow: '[Traceability] Finish Loading Executions By Flow',
    tracLoadExecutionsByFlow: '[Traceability] Load Executions By Flow',
    tracErrorTraceability: '[Traceability] Error Traceability',

    // floGetSchemesFlows: '[Flows] Get Schemes',
    GetSchemes: '[Flows] Get Schemes ',

    //GetSchemes: '[Flows] Get Schemes ',
    GetBoardUrl: '[Flows] Get Boards Url ',

    //Get Documents
    loadAllDocs: '[Docs] Load All Docs',
    loadHistory: '[Docs] Load History',
    isLoadingLoadAllDocs: '[Docs] Is Loading Load All Docs',

    //Get Menu
    loadMenu: '[Menu] Load Menu',

    //Manage Users
    loadAllUsers: '[Users] Load All Users',
    isLoadingLoadAllUsers: '[Docs] Is Loading Load All Users',
    loadAllPermissions: '[Users] Load All Permissions',
    isLoadingAllPermissions: '[Docs] Is Loading All Permissions',
    loadCompaniesPermissions: '[Users] Load Companies Permissions',
    loadGroupsPermissions: '[Users] Load Groups Permissions',


    notificaction: '[Mes] Notification'
}